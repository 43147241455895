import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiEndPoint } from 'src/app/shared/constants/apis.constant'

@Injectable({
	providedIn: 'root',
})
export class CustomizingMobileAppService {
	constructor(private http: HttpClient) {}
	UploadMarketPlaceSliderImages(body: any) {
		return this.http.post(ApiEndPoint.UploadStoreSliderImages, body)
	}
	createStoreImage(body: any) {
		return this.http.post(ApiEndPoint.createHomeSliderImageApi, body)
	}
	getSubSubCategoryArray(body: any) {
		return this.http.post(ApiEndPoint.getSubSubCategoryArray, body)
	}
	getStoreImages(CountryId: number) {
		return this.http.get(
			`${ApiEndPoint.getStoreSliderImagesApi}?CountryId=${CountryId}`
		)
	}
	updateStoreImages(body: any) {
		return this.http.put(ApiEndPoint.updateStoreSliderImagesApi, body)
	}
	updateStoreSingleImage(body: any) {
		return this.http.put(ApiEndPoint.updateStoreSingleImage, body)
	}
	deleteStoreImages(id: number) {
		return this.http.delete(
			`${ApiEndPoint.deleteStoreSliderImagesApi}?id=${id}`
		)
	}
	getCities(countryId: number) {
		return this.http.get(`${ApiEndPoint.getCities}?countryId=${countryId}`)
	}
	getOrganizations(filter: any) {
		return this.http.post(ApiEndPoint.organizationListApi, filter)
	}
	uploadSalonImage(body: any) {
		return this.http.post(ApiEndPoint.uploadSalonImageApi, body)
	}
	getHomePageDetails(countryId: number) {
		return this.http.get(
			`${ApiEndPoint.getHomePageDetailsApi}?CountryId=${countryId}`
		)
	}
}
